<template>
  <div>
    <div class="list-title">
      {{ $t("silenceConf") }}
    </div>

    <el-button type="primary" plain size="small" @click="addSilence" style="margin: 10px 0;">
      + {{ $t("handle.addSilenceConf") }}
    </el-button>

    <el-tabs type="card" v-model="tabActiveName">
      <el-tab-pane :label="$t('silenceActive')" name="first">
        <el-table :data="activeList" v-loading="loading">
          <el-table-column :label="$t('tag')">
            <template slot-scope="scope">
              <div class="label" v-for="(item, index) in scope.row.matchers" :key="index">
                <span class="label-name">
                  {{ item.name }}
                </span>
                {{ item.value ? item.value : "-" }}
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('silenceStartTime')" prop="startsAt">
            <template slot-scope="scope">
              {{ moment(scope.row.startsAt).format("YYYY-MM-DD HH:mm:ss ") }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('silenceEndTime')" prop="endsAt">
            <template slot-scope="scope">
              {{ moment(scope.row.endsAt).format("YYYY-MM-DD HH:mm:ss ") }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('creator')" prop="createdBy"> </el-table-column>

          <el-table-column :label="$t('handle.handle')">
            <template slot-scope="scope">
              <el-button plain size="small" @click="edit(scope.row)">
                {{ $t("handle.edit") }}
              </el-button>

              <el-button type="danger" plain size="small" @click="deleteSilence(scope.row)">
                {{ $t("handle.delete") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane :label="$t('expired')" name="second">
        <el-table :data="expiredList" v-loading="loading">
          <el-table-column :label="$t('tag')">
            <template slot-scope="scope">
              <div class="label" v-for="(item, index) in scope.row.matchers" :key="index">
                <span class="label-name">
                  {{ item.name }}
                </span>
                {{ item.value ? item.value : "-" }}
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('silenceStartTime')" prop="startsAt">
            <template slot-scope="scope">
              {{ moment(scope.row.startsAt).format("YYYY-MM-DD HH:mm:ss ") }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('silenceEndTime')" prop="endsAt">
            <template slot-scope="scope">
              {{ moment(scope.row.endsAt).format("YYYY-MM-DD HH:mm:ss ") }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('creator')" prop="createdBy"> </el-table-column>

          <el-table-column>
            <template slot-scope="scope">
              <el-button plain size="small" @click="edit(scope.row)">
                {{ $t("handle.reCreate") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :title="$t('silenceConf')"
      :visible.sync="dialogVisible"
      top="30px"
      width="800px"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <el-form :model="form" label-position="top" size="small" ref="form">
        <el-form-item
          :label="$t('silenceTime')"
          prop="timeRange"
          :rules="[{ required: true, message: $t('rules.require'), trigger: 'change' }]"
        >
          <el-date-picker
            v-model="form.timeRange"
            :clearable="false"
            type="datetimerange"
            range-separator="-"
            size="small"
            align="center"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item :label="$t('silenceLabel')">
          <el-button
            type="default"
            size="small"
            style="margin-bottom: 10px; "
            plain
            icon="plus"
            @click="form.matchers.push({ name: '', value: '', isRegex: false })"
          >
            + {{ $t("handle.addSilenceLabel") }}
          </el-button>
          <div v-for="(item, index) in form.matchers" :key="index">
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item
                  :prop="'matchers.' + index + '.name'"
                  :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                >
                  <el-input v-model="item.name" size="small">
                    <template slot="prepend">
                      Key
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item
                  :prop="'matchers.' + index + '.value'"
                  :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
                >
                  <el-input v-model="item.value" size="small">
                    <template slot="prepend">
                      Value
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="2">
                <el-button
                  icon="el-icon-delete"
                  circle
                  @click="form.matchers.splice(index, 1)"
                  size="small"
                ></el-button>
              </el-col>
            </el-row>
          </div>
        </el-form-item>

        <el-form-item
          :label="$t('silenceComment')"
          prop="comment"
          :rules="[{ required: true, message: $t('rules.require'), trigger: 'change' }]"
        >
          <el-input v-model="form.comment"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="submitLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSilences, addSilence, deleteSilence } from "api/alert";
import { cloneDeep } from "lodash";
import moment from "moment";

export default {
  data() {
    return {
      list: [],
      activeList: [],
      expiredList: [],
      loading: true,
      dialogVisible: false,
      submitLoading: false,
      tabActiveName: "first",
      form: {
        startsAt: "",
        endsAt: "",
        matchers: [],
        createdBy: "",
        comment: "",
        timeRange: []
      }
    };
  },

  methods: {
    moment,
    getSilences() {
      this.loading = true;

      getSilences().then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.list = response.data;

          this.activeList = [];
          this.expiredList = [];

          this.list.forEach(item => {
            if (item.status.state === "active") {
              this.activeList.push(item);
            }

            if (item.status.state === "expired") {
              this.expiredList.push(item);
            }
          });
        }
      });
    },

    addSilence() {
      this.dialogVisible = true;
      this.form = {
        startsAt: "",
        endsAt: "",
        matchers: [],
        createdBy: "",
        comment: "",
        timeRange: []
      };
    },

    deleteSilence(row) {
      this.$confirm(this.$t("delete", []), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          deleteSilence(row.id).then(response => {
            if (response.code === 0) {
              this.getSilences();
            }
          });
        })
        .catch(() => {});
    },

    edit(row) {
      this.dialogVisible = true;
      this.form = cloneDeep(row);

      this.$set(this.form, "timeRange", [row.startsAt, row.endsAt]);
    },

    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.submitLoading = true;

          if (this.form.timeRange.length > 0) {
            this.form.startsAt = this.form.timeRange[0];
            this.form.endsAt = this.form.timeRange[1];
          }

          this.form.createdBy = this.userName;

          addSilence(this.form).then(response => {
            this.submitLoading = false;
            if (response.code === 0) {
              this.dialogVisible = false;
              this.getSilences();
            }
          });
        }
      });
    }
  },

  mounted() {
    this.getSilences();
  },

  computed: {
    userName() {
      return this.$store.state.app.userName;
    }
  }
};
</script>
